const EntitiesMenu = [
  {
    heading: "menu",
    route: "/certificate",
    pages: [
      {
        heading: "Certification DashBoard",
        route: "/certificate/certificate-dashboard",
        fontIcon: "fa-search",
      },
      {
        heading: "Search",
        route: "/certificate/search",
        fontIcon: "fa-certificate",
      },
      {
        heading: "Training Certificate",
        route: "/certificate/training-certificate",
        fontIcon: "fa-certificate",
      },
      {
        heading: "Training Certificate Information",
        route: "/certificate/training-certificate-Information",
        fontIcon: "fa-info",
      },
    ],
  },
];

export default EntitiesMenu;
